<template>
  <div id="app">
    <div class="boxed_wrapper">
      <div class="preloader"></div>
      <header-1></header-1>
      <router-view :key="$route.fullPath"></router-view>
       <button class="scroll-to-top-btn"  v-on:click="scrollToTop" id="scrollToTopBtn">☝️</button>
    </div>

  </div>
</template>
 
<script>
import Header from './components/Header.vue'

export default {
  components: {
    'header-1': Header
  },
   methods: {
         scrollToTop() {
         let currentScroll=document.documentElement.scrollTop,
         int=setInterval (frame,6)
         function frame(){
            if(0>currentScroll)
            clearInterval(int)
            else{
                currentScroll=currentScroll-12
                document.documentElement.scrollTop=currentScroll
            }
         }
         }
}
}
</script>
<style >
#scrollToTopBtn {
  position: fixed;
  left: 24px;
  bottom: 0;
  float: left;
  z-index: 2;
  border:  3px solid 000;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 28px;
  line-height: 68px;
  width: 10px;
}
</style>
