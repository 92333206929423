<template>
    <div class="register">
        <div class="container">
            <form class="form-horizontal" @submit.prevent="Register()" role="form">
                <h2>{{$t('login.register')}}</h2>
                <div class="form-group">
                    <label for="firstName" class="col-sm-3 control-label">{{$t('login.Toʻliq ism')}}</label>
                    <div class="col-sm-9">
                        <input type="text" minlength="6" v-model="register.name" id="firstName" placeholder="First name"
                            class="form-control" autofocus required>
                        <span class="help-block">{{$t('login.Familiya')}}: Smit, Garri</span>
                    </div>
                </div>
                <div class="form-group">
                    <label for="email" class="col-sm-3 control-label">Email</label>
                    <div class="col-sm-9">
                        <input type="email" v-model="register.email" id="email" placeholder="Email" class="form-control"
                            required>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password" class="col-sm-3 control-label">{{$t('login.Parol')}}</label>
                    <div class="col-sm-9">
                        <input type="password" minlength="6" v-model="register.password" id="password" required
                            placeholder="Password" class="form-control">
                    </div>
                </div>
                <div class="form-group">
                    <label for="password" class="col-sm-3 control-label">{{$t('login.Parolni Tasdiqlash')}}</label>
                    <div class="col-sm-9">
                        <input type="password" minlength="6" v-model="register.password_confirmation" id="password"
                            placeholder="confirm password" class="form-control" required>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-9 col-sm-offset-3">
                        <button type="submit" class="btn btn-primary btn-block">{{$t('login.register')}}</button>
                    </div>
                </div>
                <div class="footer-link padding-top--24">
                    <span>{{$t('login.Hisobingiz bormi?')}} <router-link to="/login">{{$t('login.sign')}}</router-link></span>
                </div>
            </form>
        </div>




    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            register: {
                email: '',
                name: '',
                password: '',
                password_confirmation: ''
            }
        }
    },
    methods: {
        async Register() {
            try {
                const data = await axios.post('https://api.stateartmuseum.uz/api/auth/register', this.register)
                console.log(data);
                this.$toast.success(`Ro'yhatdan o'tdingiz !`, {
                    position: 'bottom-right'
                })
                const login = await axios.post('https://api.stateartmuseum.uz/api/auth/login', { email: this.register.email, password: this.register.password })
                localStorage.setItem('muzey_token', login.data.access_token)
                localStorage.setItem('muzey_user', login.data.user.name)
                localStorage.setItem('muzey-user-id', login.data.user.id)
                this.$router.push('/')
                setTimeout(() => {
                    window.location.reload()
                }, 500);
            }
            catch (error) {
                console.log(error.response);
                if (error.response.data === `{"password":["Parol tasdiqlangani bilan mos kelmadi."]}`) {
                    this.$toast.error(`Parol to'g'ri tasdiqlanmagan !`, {
                        position: 'bottom-right'
                    })
                }
                else {
                    this.$toast.error(`Serverda xatolik yuz berdi !`, {
                        position: 'bottom-right'
                    })
                }
            }

        }
    }
}
</script>
 


<style scoped>
.register {
    background: linear-gradient(rgba(0, 0, 0, 0.658), rgba(0, 0, 0, 0.514)), url('../assets/images/resources/register-1.jpg') !important;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
}

.register {
    padding-top: 6rem;
}

.register h2 {
    position: relative;
    top: 0;
    left: -32px;
    width: 100%
}

.register *[role="form"] {
    max-width: 530px;
    padding: 15px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 0.3em;
}

.register .form-group {
    margin-left: 70px;
    width: 100%;
}

.register *[role="form"] h2 {
    margin-left: 5em;
    margin-bottom: 1em;
}

.register .footer-link {
    text-align: center;
}
</style>